'use strict';

Gri.module({
  name: 'modal-gamma',
  ieVersion: null,
  $el: $('.modal-gamma'),
  container: '.modal-gamma',
  fn: function () {
    
  }
});
